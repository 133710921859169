html {
  font-family: var(--font-family); 
}

body {
  font-family: inherit;
  box-sizing: border-box;
  background-color: #fff;
}

p,h1,h2,h3,h4,h5,h6 {
  font-family: inherit; 
}
